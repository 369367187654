import React, { useEffect } from 'react';
import Aos from 'aos';
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import 'aos/dist/aos.css';


const SimpleHero = ({ pretitle, title, heroTitle }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 60 });
  }, []);

  const pageTypes = ["ABOUT","MEISTÄ","CASES","TYÖT","CULTURE","KULTTUURI","CAREERS","REKRYTOINTI","NEWS","UUTISET","CONTACT","YHTEYSTIEDOT"];
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
        let theTitle = <h1 className="hero__title heading-1 text-brightTurquoiseApprox hyphens-auto--max-width relative w-full">{children}</h1>
        if (!pageTypes.includes(pretitle.toUpperCase())) {
          theTitle = <h2 className="hero__title heading-1 text-brightTurquoiseApprox hyphens-auto--max-width relative w-full">{children}</h2>
        }
        return theTitle
      },
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  }

  let heading = "";
  if (title) {
    heading = renderRichText(title, options);
  }

  // const title = heroTitle

  return (
    <section className="hero mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20 relative w-full" data-aos="fade-up">
      <div className="max-w-screen-xl mx-auto pt-20 md:pt-28 flex justify-center items-center" style={{marginBottom: '180px'}}>

        <div className="w-full">
          {pretitle && pageTypes.includes(pretitle.toUpperCase()) &&
            <p className="hero__pretitle mb-1 pretitle text-brightTurquoiseApprox">{pretitle}</p>
          }

          {pretitle && !pageTypes.includes(pretitle.toUpperCase()) &&
            <h1 className="hero__pretitle mb-1 pretitle text-brightTurquoiseApprox">{pretitle}</h1>
          }

          {heroTitle &&
            <div>{heading}</div>
          }
        </div>
      </div>
    </section >
  )
}

export default SimpleHero;