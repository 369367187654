import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts/layout';
import { getSrc } from 'gatsby-plugin-image';
import SimpleHero from '../components/hero/simpleHero';
import ContentSections from "../components/contentSections/contentSections";
import ContactDialog from '../components/dialog/contactDialog';
import NewsletterDialog from '../components/dialog/newsletterDialog';
import Seo from '../components/seo/seo';

const Page = ({ data }) => {

  const { pretitle, title, heroTitle, contentSections, colorTheme, node_locale, seoSettings } = data.contentfulPage;
  const seoImage = seoSettings?.image?.gatsbyImageData ? getSrc(seoSettings?.image?.gatsbyImageData) : null;

  let newsitems = [];

  if (node_locale === "en-US") {
    newsitems = data.newsitems.nodes.filter(item => (!!item.whereContentWillBeShown &&
      (item.whereContentWillBeShown[0]) !== "Show only in Finnish"));
  } else {
    newsitems = data.newsitems.nodes.filter(item => (!!item.whereContentWillBeShown &&
      (item.whereContentWillBeShown[0]) !== "Show only in English"));
  }

  const dialogRef = useRef(null);

  const openDialog = () => {
    if (typeof window !== 'undefined') {
      // Click the Send Message button, datalayer
      // when the 'Send Message' button is clicked
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': "sendMessageClick"
      });
    }
    dialogRef.current.openContactDialog();
  }

  const dialogRefNewsletter = useRef(null);

  const openNewsletterDialog = () => {
    dialogRefNewsletter.current.openNewsletterDialog();
  }

  const formData = data.forms.edges[0].node;

  return (
    <Layout colorTheme={colorTheme} nodeLocale={node_locale} pretitle={pretitle}>
      <Seo
        seoTitle={seoSettings?.seoTitle ? seoSettings?.seoTitle : pretitle}
        seoDesc={seoSettings?.description ? seoSettings?.description.description : heroTitle}
        keywords={seoSettings?.keywords ? seoSettings?.keywords : null}
        image={seoImage ? seoImage : null}
        url={typeof window !== 'undefined' ? window.location.href : ''}
        lang={node_locale.substr(0, 2)}
        noIndex={seoSettings?.hidePageFromSearchEnginesNoindex ? 'nofollow' : 'follow'}
        noFollow={seoSettings?.excludeLinksFromSearchRankingsNofollow ? 'noindex' : 'index'}
      />
      <SimpleHero
        pretitle={pretitle}
        title={title}
        heroTitle={heroTitle}
      />
      <ContactDialog
        ref={dialogRef}
        sectionTitle={formData.sectionTitle}
        firstName={formData.firstName}
        lastName={formData.lastName}
        email={formData.email}
        phone={formData.phone}
        message={formData.message.message}
        texts={formData.texts}
      />
      <NewsletterDialog
        ref={dialogRefNewsletter}
        email={formData.email}
        texts={formData.texts}
      />
      <ContentSections nodeLocale={node_locale} contentSections={contentSections} newsitems={newsitems} openDialog={openDialog} formData={formData} openNewsletterDialog={openNewsletterDialog} colorTheme={colorTheme}/>
    </Layout>
  )
}

export const pageQuery = graphql`
query defaultPageQuery($slug: String, $locale: String) {
  contentfulPage( slug: {eq: $slug }, node_locale: { eq: $locale }) {
      slug
      pretitle
      heroTitle
      title {
        raw
      }
      contentSections {
        ... on Node {
          ... on ContentfulPeopleSection {
            __typename
            id
            title
            addPeople {
              ... on Node {
                ... on ContentfulPerson {
                  name
                  position
                  email
                  telephoneNumber
                  imageBackgroundColor
                  image {
                    gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
            }
            columnCount
          }
          ... on ContentfulNewsSection {
            __typename
            id
            sectionTitle
            sectionName
            loadMoreButtonText
          }
          ... on ContentfulTextSection {
            __typename
            id
            title
            richText {
              raw
            }
            quote {
              raw
            }
            ctaButtonLinks {
              ... on Node { 
                ... on ContentfulExternalLink {
                  linkName
                  linkUrl
                }
                ... on ContentfulInternalLink {
                  id
                  linkName
                  linkToEntry {
                    ... on ContentfulPage {
                      id
                      slug
                    }
                  }
                }
                ... on ContentfulAssetLink {
                  collectUserInfo
                  linkName
                  addAsset {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on ContentfulTextSectionQuote {
            __typename
            id
            sectionName
            heading {
              heading
            }
            richText {
              raw
            }
            quote {
              raw
            }
          }
          ... on ContentfulVideoImageSection {
            __typename
            id
            videoOrImage {
              gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
              file {
                contentType
                url
              }
            }
            fullWidth
            linkUrl
          }
          ... on ContentfulMapbox {
            __typename
            token
            styleUrl
          }
          ... on ContentfulCtaSection {
            __typename
            id
            title
            titleColor
            textField {
              textField
            }
            ctaLinks {
              ... on Node { 
                ... on ContentfulExternalLink {
                  linkName
                  linkUrl
                }
                ... on ContentfulInternalLink {
                  id
                  linkName
                  linkToEntry {
                    ... on ContentfulPage {
                      id
                      slug
                    }
                  }
                }
              }
            }
            textAlignCenter
            backgroundVideo {
              file {
                url
              }
            }
            videoAlphaChannel {
              file {
                url
              }
            }
          }
          ... on ContentfulValuesSection {
            __typename
            id
            sectionTitle
            values {
              title
              image {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulTimelineSection {
            __typename
            id
            sectionTitle
            item {
              key
              value
            }
          }
          ... on ContentfulGroupStructureSection {
            __typename
            id
            title
            logo {
              file {
                url
              }
            }
            logoMobile {
              file {
                url
              }
            }
            companies {
              key
              value
            }
          }
          ... on ContentfulListSection {
            __typename
            id
            sectionTitle
            listItems {
              key
              value
            }
          }
          ... on ContentfulTextBanner {
            __typename
            id
            sectionTitle
            text {
              raw
            }
          }
          ... on ContentfulCaseSection {
            __typename
            id
            sectionTitle
            title
            client
            anchorLink
            serviceCategory
            text {
              raw
            }
          }
          ... on ContentfulRefrenceSection {
            __typename
            id
            sectionTitle
            logos {
              gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          ... on ContentfulFormSection {
            __typename
            id
            sectionTitle
            firstName
            lastName
            email
            phone
            message {
              message
            }
            texts {
              send,
              requiredFirstName,
              requiredLastName,
              invalidEmail,
              excludedEmail,
              requiredPhone,
              requiredMessage,
              thankyouMessage,
              existingContact
            }
          }
          ... on ContentfulTextBoxSection {
            __typename
            id
            sectionTitle
            textBlocks {
              ... on Node {
                ... on ContentfulTextBlock {
                  id
                  blockName
                  blockContent {
                    raw
                  }
                }
              }
            }
            showContentInColumns
          }
          ... on ContentfulProcessSection {
            __typename
            id
            sectionTitle
            sectionDescription {
              sectionDescription
            }
            phases {
              text {
                raw
              }
              image {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulContentBlockSection {
            __typename
            id
            sectionTitle
            contentBlock {
              blockPretitle
              blockTitle
              content {
                raw
                references {
                  ... on ContentfulPage {
                    __typename
                    contentful_id
                    slug
                  }
                }
              }
            }
          }
          ... on ContentfulTextSectionWithColumns {
            __typename
            id
            sectionName
            sectionTitle
            sectionIngress {
              sectionIngress
            }
            textBlocks {
              ... on Node {
                ... on ContentfulTextBlock {
                  id
                  blockName
                  blockContent {
                    raw
                  }
                }
              }
            }
          }
          ... on ContentfulInformationSection {
            __typename
            id
            sectionTitle
            informationCards {
              id
              ... on Node {
                ... on ContentfulInformationCard {
                  content {
                    raw
                  }
                }
              }
            }
          }
          ... on ContentfulQuoteSection {
            __typename
            id
            text {
              raw
            }
          }
          ... on ContentfulGraphSection {
            __typename
            id
            sectionName
            sectionTitle
            sectionDescription {
              sectionDescription
            }
            graph {
              file {
                url
              }
            }
          }
          ... on ContentfulTableWithColumnSection {
            __typename
            id
            sectionName
            columnArea {
              blockName
              blockContent {
                raw
              }
            }
          }
          ... on ContentfulBackgroundImageWithQuoteSection {
            __typename
            id
            sectionName
            quote {
              quote
            }
            quoteAuthorName
            quoteAuthorTitle
          }
          ... on ContentfulNumberedListSection {
            __typename
            id
            sectionName
            listArea {
              blockName
              blockContent {
                raw
              }
            }
          }
          ... on ContentfulTextListSection {
            __typename
            id
            title
            contentList {
              title
              sectionName
              richText {
                raw
              }
              ctaButtonLinks {
                ... on ContentfulExternalLink {
                  id
                  linkUrl
                  linkName
                }
              }
            }
          }
          ... on ContentfulLink {
            __typename
            id
            linkName
            linkUrl
            textWithIconList {
              sectionName
              sectionTitle
              sectionDescription {
                sectionDescription
              }
              graph {
                file {
                  url
                }
              }
            }
          }
        }
      }
      colorTheme
      node_locale
      seoSettings {
        seoTitle
        description {
          description
        }
        keywords
        image {
          gatsbyImageData
        }
        excludeLinksFromSearchRankingsNofollow
        hidePageFromSearchEnginesNoindex
      }
    }
    newsitems: allContentfulArticle (
      sort: {order: DESC, fields: date}
        filter: {
          slug: {ne: "systemfile"},
          title:{ne: null},
          node_locale: { eq: $locale }
        }
      ) {
      nodes {
        id
        excerpt
        authorName
        authorTitle
        newsType
        slug
        title
        date
        setArticleCardTextColor
        whereContentWillBeShown
        mainImage {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
    forms: allContentfulFormSection (filter: {node_locale: { eq: $locale }}){
      edges {
        node {
          __typename
          id
          sectionTitle
          firstName
          lastName
          email
          phone
          message {
            message
          }
          texts {
            send,
            requiredFirstName,
            requiredLastName,
            invalidEmail,
            requiredPhone,
            requiredMessage,
            thankyouMessage,
            existingContact,
            subscribeOurNewsletterTitle,
            subscribeOurNewsletter,
            thankyouNewsletterMessage,
            failedNewsletterMessage,
            simpleContactFormTitle
          }
        }
      }
    }
  }
  
`

export default Page;